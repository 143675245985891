import * as React from "react";
import ServicesList from "../Section/ServicesList";
import Banner from "../Section/Banner";
import RequestForm from "../Section/RequestForm";
import BusinessHours from "../Section/BusinessHours";

export interface IContactProps {}

export interface IContactState {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  message: string;
}

export default class Contact extends React.Component<
  IContactProps,
  IContactState
> {
  constructor(props: IContactProps) {
    super(props);

    this.state = {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      message: "",
    };
  }

  handleSubmit = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    // Send form data to server
  };

  render() {
    return (
      <div>
        <Banner isHome={false} pageName="Kontakt" color="violet" />
        {/*<!-- Component: Two columns even layout --> */}
        <section
          className="
      items-center justify-center w-full px-4 py-8 mx-auto
      "
        >
          <div className="container px-6 m-auto">
            <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
              <div className="col-span-4 lg:col-span-6">
                <div className="overflow-hidden bg-white rounded shadow-lg text-slate-500 shadow-slate-600 lg:h-60 text-center">
                  <div className="p-6 lg:py-0 text-center">
                    <i className="fas fa-phone-alt text-slate-900 lg:mt-10"></i>
                    <p className="font-bold text-slate-900 uppercase">
                      {" "}
                      TelephonNummer :
                      <span className="font-normal">
                        <a href="tel:+4368120452405">+4368120452405</a>
                      </span>
                    </p>
                    <i className="fas fa-envelope text-slate-900 lg:mt-3"></i>
                    <p className="font-bold text-slate-900 uppercase">
                      Email :
                      <span className="font-normal">
                        <a href="mailto:office@alphanet.co.at">
                          office@alphanet.co.at{" "}
                        </a>
                      </span>
                    </p>
                    <i className="fas fa-map-marker-alt text-slate-900 lg:mt-3"></i>
                    <p className="font-bold text-slate-900 uppercase">
                      {" "}
                      Adresse :
                      <span className="font-normal">
                        <a href="https://maps.app.goo.gl/hZpYoXbcxK1wk5Bb7">
                          Alphanet GmbH, 1100 Wien, GudrunStrasse 134/3-4
                        </a>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-span-4 lg:col-span-6">
                <div className="overflow-hidden bg-white rounded shadow-lg text-slate-500 shadow-slate-600 lg:h-60">
                  <div className="p-6 mx-auto">
                    <h1 className="text-2xl font-bold text-slate-900 text-center">
                      Öffnungszeiten
                    </h1>
                        <div className="text-center mx-auto">
                        <BusinessHours/>
                        </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*<!-- End Two columns even layout --> */}
        <RequestForm />

        {/*<!-- Component: Basic accordion
             <div
          className="
      flex flex-col items-center justify-center
      w-full px-4 py-8 mx-auto
      md:px-10 md:py-16
      lg:px-12 lg:py-20
      xl:px-16 xl:py-24
      2xl:px-20 2xl:py-28">
                    <section className="w-full divide-y rounded divide-slate-300 ">
            <details className="p-4 group" open>
              <summary className="relative cursor-pointer list-none pr-8 font-medium text-slate-700 transition-colors duration-300 focus-visible:outline-none group-hover:text-slate-900  [&::-webkit-details-marker]:hidden">
                where are we located?
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="absolute right-0 w-4 h-4 transition duration-300 top-1 shrink-0 stroke-slate-700 group-open:rotate-45"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  aria-labelledby="title-ac01 desc-ac01"
                >
                  <title id="title-ac01">Open icon</title>
                  <desc id="desc-ac01">
                    icon that represents the state of the summary
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4v16m8-8H4"
                  />
                </svg>
              </summary>
              <p className="mt-4 text-slate-500">Alphanet GmbH</p>
            </details>
            <details className="p-4 group" open>
              <summary className="relative cursor-pointer list-none pr-8 font-medium text-slate-700 transition-colors duration-300 focus-visible:outline-none group-hover:text-slate-900  [&::-webkit-details-marker]:hidden">
                Wie kann ich einen Termin vereinbaren?
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="absolute right-0 w-4 h-4 transition duration-300 top-1 shrink-0 stroke-slate-700 group-open:rotate-45"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  aria-labelledby="title-ac02 desc-ac02"
                >
                  <title id="title-ac02">Open icon</title>
                  <desc id="desc-ac02">
                    icon that represents the state of the summary
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4v16m8-8H4"
                  />
                </svg>
              </summary>
              <p className="mt-4 text-slate-500">
                Sie können uns jederzeit unter der Nummer{" "}
                <strong>+4368120452405</strong> erreichen.
              </p>
            </details>
            <details className="p-4 group" open>
              <summary className="relative cursor-pointer list-none pr-8 font-medium text-slate-700 transition-colors duration-300 focus-visible:outline-none group-hover:text-slate-900  [&::-webkit-details-marker]:hidden">
                Wie lange dauert die Reparatur?
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="absolute right-0 w-4 h-4 transition duration-300 top-1 shrink-0 stroke-slate-700 group-open:rotate-45"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  aria-labelledby="title-ac03 desc-ac03"
                >
                  <title id="title-ac03">Open icon</title>
                  <desc id="desc-ac03">
                    icon that represents the state of the summary
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4v16m8-8H4"
                  />
                </svg>
              </summary>
              <p className="mt-4 text-slate-500">
                Die Reparatur dauert in der Regel 1-2 Tage.
              </p>
            </details>
            <details className="p-4 group" open>
              <summary className="relative cursor-pointer list-none pr-8 font-medium text-slate-700 transition-colors duration-300 focus-visible:outline-none group-hover:text-slate-900  [&::-webkit-details-marker]:hidden">
                Wie viel kostet die Reparatur?
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="absolute right-0 w-4 h-4 transition duration-300 top-1 shrink-0 stroke-slate-700 group-open:rotate-45"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  aria-labelledby="title-ac04 desc-ac04"
                >
                  <title id="title-ac04">Open icon</title>
                  <desc id="desc-ac04">
                    icon that represents the state of the summary
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4v16m8-8H4"
                  />
                </svg>
              </summary>
              <p className="mt-4 text-slate-500">
                Die Kosten variieren je nach Gerät und Schaden.
              </p>
            </details>
            <details className="p-4 group" open>
              <summary className="relative cursor-pointer list-none pr-8 font-medium text-slate-700 transition-colors duration-300 focus-visible:outline-none group-hover:text-slate-900  [&::-webkit-details-marker]:hidden">
                Wie kann ich bezahlen?
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="absolute right-0 w-4 h-4 transition duration-300 top-1 shrink-0 stroke-slate-700 group-open:rotate-45"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  aria-labelledby="title-ac05 desc-ac05"
                >
                  <title id="title-ac05">Open icon</title>
                  <desc id="desc-ac05">
                    icon that represents the state of the summary
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4v16m8-8H4"
                  />
                </svg>
              </summary>
              <p className="mt-4 text-slate-500">
                Sie können bar oder per EC-Karte bezahlen.
              </p>
            </details>
            <details className="p-4 group" open>
              <summary className="relative cursor-pointer list-none pr-8 font-medium text-slate-700 transition-colors duration-300 focus-visible:outline-none group-hover:text-slate-900  [&::-webkit-details-marker]:hidden">
                Wie lange habe ich Garantie?
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="absolute right-0 w-4 h-4 transition duration-300 top-1 shrink-0 stroke-slate-700 group-open:rotate-45"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  aria-labelledby="title-ac06 desc-ac06"
                >
                  <title id="title-ac06">Open icon</title>
                  <desc id="desc-ac06">
                    icon that represents the state of the summary
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4v16m8-8H4"
                  />
                </svg>
              </summary>
              <p className="mt-4 text-slate-500">
                Sie haben 3 Monate Garantie auf die Reparatur.
              </p>
            </details>
            <details className="p-4 group" open>
              <summary className="relative cursor-pointer list-none pr-8 font-medium text-slate-700 transition-colors duration-300 focus-visible:outline-none group-hover:text-slate-900  [&::-webkit-details-marker]:hidden">
                Wie kann ich mein Gerät einschicken?
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="absolute right-0 w-4 h-4 transition duration-300 top-1 shrink-0 stroke-slate-700 group-open:rotate-45"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  aria-labelledby="title-ac07 desc-ac07"
                >
                  <title id="title-ac07">Open icon</title>
                  <desc id="desc-ac07">
                    icon that represents the state of the summary
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4v16m8-8H4"
                  />
                </svg>
              </summary>
              <p className="mt-4 text-slate-500">
                Sie können Ihr Gerät jederzeit bei uns vorbeibringen oder per
                Post einschicken.
              </p>
            </details>
            <details className="p-4 group" open>
              <summary className="relative cursor-pointer list-none pr-8 font-medium text-slate-700 transition-colors duration-300 focus-visible:outline-none group-hover:text-slate-900  [&::-webkit-details-marker]:hidden">
                Wie kann ich mein Gerät abholen?
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="absolute right-0 w-4 h-4 transition duration-300 top-1 shrink-0 stroke-slate-700 group-open:rotate-45"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  aria-labelledby="title-ac08 desc-ac08"
                >
                  <title id="title-ac08">Open icon</title>
                  <desc id="desc-ac08">
                    icon that represents the state of the summary
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4v16m8-8H4"
                  />
                </svg>
              </summary>
              <p className="mt-4 text-slate-500">
                Sie können Ihr Gerät jederzeit bei uns abholen oder per Post
                abholen lassen.
              </p>
            </details>
          </section>
                  </div>
          -->
           */}

        {/*<!-- End Basic accordion --> */}

        {/*add 3 card To follow us on social media */}
        {/*<!-- Component: Three columns even layout --> */}
        <section className="mt-6">
          <div className="container px-6 m-auto text-center items-center">
            <div className="grid grid-cols-1 gap-4 md:grid-cols-3 lg:grid-cols-12 text-center m-auto items-center">
            <div className="col-span-2">
                
              </div>
              <div className="col-span-2">
                <div className="flex flex-col items-center justify-center cursor-pointer">
                  <a href="https://www.facebook.com/AlphanetWerkstattApple/reviews">
                    <img
                      className="w-32 h-32"
                      src="../../../img/social/facebook.png"
                      alt=""
                    />
                    <p className="text-xl font-bold text-center mt-3 uppercase">
                      Facebook
                    </p>
                  </a>
                </div>
              </div>
              <div className="col-span-2">
                <div className="flex flex-col items-center justify-center cursor-pointer">
                  <a href="https://www.instagram.com/alphanetserviceprovider/">
                    <img
                      className="w-32 h-32"
                      src="../../../img/social/instagram.png"
                      alt=""
                    />
                    <p className="text-xl font-bold text-center mt-3 uppercase">
                      Instagram
                    </p>
                  </a>
                </div>
               
              </div>
              <div className="col-span-2">
                <div className="flex flex-col items-center justify-center cursor-pointer">
                  <a href="https://maps.app.goo.gl/Vm1w4cwEGquk9urS7">
                    <img
                      className="w-32 h-32"
                      src="https://img.icons8.com/ios/452/google-logo.png"
                      alt=""
                    />
                    <p className="text-xl font-bold text-center mt-3 uppercase">
                      Google
                    </p>
                  </a>
                </div>

              </div>
              <div className="col-span-2">
                <div className="flex flex-col items-center justify-center cursor-pointer">
                  <a href="https://www.tiktok.com/@alphanet.service">
                    <img
                      className="w-32 h-32"
                      src="
                      https://img.icons8.com/ios/452/tiktok.png
                      "
                      alt=""
                    />
                    <p className="text-xl font-bold text-center mt-3 uppercase">
                      Tiktok
                    </p>
                  </a>
                </div>
                </div>
        
            </div>
          </div>
        </section>
        {/*<!-- End Three columns even layout --> */}
        <div>
          <div className="flex flex-col items-center justify-center            w-full px-4 py-8 mx-auto ">

            <div className="grid grid-cols-1 md:grid-cols-1 gap-4 mt-8"></div>
          </div>
        </div>

      </div>
    );
  }
}
