import React, { Component } from 'react';
import getBusinessHours from '../../ts/googleBusinessService';

interface State {
  openingHours: string[];
  loading: boolean;
  error: string | null;
}

class BusinessHours extends Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      openingHours: [],
      loading: true,
      error: null,
    };
  }

  private getBusinessHours = async () => {
    try {
      const openingHours = await getBusinessHours('ChIJ6bu4xs-pbUcRPpQgl8hzyFA');
      this.setState({ openingHours, loading: false });
    } catch (error: any) {
      this.setState({ error: error.message, loading: false });
    }
  }

  componentDidMount() {
    this.getBusinessHours();
  }

  render() {
    const { openingHours, loading, error } = this.state;

    if (loading) {
      return <div>Loading...</div>;
    }

    if (error) {
      return <div>Error: {error}</div>;
    }

    return (
      <div className="mx-auto text-center">
        <div className="p-3">
          {openingHours.length === 0 ? (
            <div className="mt-2 text-gray-600">No opening hours available</div>
          ) : (
            <ul className="text-gray-800 mx-auto">
              {openingHours.map((day, index) => (
                <li key={index} className="mx-auto">
                  <span className="font-bold mr-3">{day.split(': ')[0]}</span>
                  <span>{day.split(': ')[1]}</span>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    );
  }
}

export default BusinessHours;
