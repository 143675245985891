import * as React from "react";
import Cookies from "js-cookie";

export interface ICookiescomponentProps {}

export interface ICookiescomponentState {
  display: boolean;
}

export default class Cookiescomponent extends React.Component<
  ICookiescomponentProps,
  ICookiescomponentState
> {
  constructor(props: ICookiescomponentProps) {
    super(props);

    this.state = {
      display: !Cookies.get("cookie"), // Show banner if no cookie is set
    };
  }

  handleAccept = () => {
    Cookies.set("cookie", "true", { expires: 30 }); // Set cookie to accepted with a 30-day expiration
    this.setState({ display: false });
  };

  handleDecline = () => {
    Cookies.set("cookie", "false", { expires: 30 }); // Set cookie to declined with a 30-day expiration
    this.setState({ display: false });
  };

  public render() {
    if (!this.state.display) {
      return null; // Don't render anything if display is false
    }

    return (
      <div>
        <div
          className="fixed bottom-0 w-full bg-white shadow-lg z-50 transition duration-500 ease-in-out block"
        >
          <div className="container mx-auto py-4 px-4">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-gray-800">
                  Wir verwenden Cookies, um Ihr Erlebnis auf unserer Website zu
                  verbessern. Durch das Surfen auf dieser Website stimmen Sie
                  der Verwendung von Cookies zu.
                </p>
              </div>
              <div className="flex items-center justify-between space-x-4">
                <button
                  onClick={this.handleAccept}
                  className="bg-green-500 hover:bg-green-400 text-white px-4 py-2 rounded-md"
                >
                  Akzeptieren
                </button>
                <button
                  onClick={this.handleDecline}
                  className="bg-red-500 hover:bg-red-400 text-white px-4 py-2 rounded-md"
                >
                  Nichtakzeptanz
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
