import * as React from 'react';
import Banner from '../Section/Banner';


interface Image {
    src: string;
    alt: string;
    category: string;
  }
  
  interface Button {
    label: string;
    category: string;
  }

export interface IGalleryProps {
}

export interface IGalleryState {
    images: Image[];
    filteredImages: Image[];
    buttons: Button[];
}


export default class Gallery extends React.Component<IGalleryProps, IGalleryState> {
  constructor(props: IGalleryProps) {
    super(props);

    const images: Image[] = [
        { src: '../../../img/gallery/gallery-1/1.jpeg', alt: '', category: 'airpods' },
        { src: '../../../img/gallery/gallery-1/2.jpeg', alt: '', category: 'airpods' },
        { src: '../../../img/gallery/gallery-1/3.jpeg', alt: '', category: 'airpods' },
        { src: '../../../img/gallery/gallery-1/4.jpeg', alt: '', category: 'airpods' },
        { src: '../../../img/gallery/gallery-1/5.jpeg', alt: '', category: 'airpods' },
        { src: '../../../img/gallery/gallery-2/6.jpeg', alt: '', category: 'airpods' },
        { src: '../../../img/gallery/gallery-2/7.jpeg', alt: '', category: 'airpods' },
        { src: '../../../img/gallery/gallery-2/8.jpeg', alt: '', category: 'airpods' },
        { src: '../../../img/gallery/gallery-2/9.jpeg', alt: '', category: 'airpods' },
        { src: '../../../img/gallery/gallery-2/10.jpeg', alt: '', category: 'accessories' },
        { src: '../../../img/gallery/gallery-2/11.jpeg', alt: '', category: 'accessories' },
        { src: '../../../img/gallery/gallery-2/12.jpeg', alt: '', category: 'accessories' },
        { src: '../../../img/gallery/gallery-2/13.jpeg', alt: '', category: 'accessories' },
        { src: '../../../img/gallery/gallery-2/14.jpeg', alt: '', category: 'accessories' },
        { src: '../../../img/gallery/gallery-2/15.jpeg', alt: '', category: 'accessories' },
        { src: '../../../img/gallery/gallery-2/16.jpeg', alt: '', category: 'accessories' },
        { src: '../../../img/gallery/gallery-2/17.jpeg', alt: '', category: 'accessories' },
        { src: '../../../img/gallery/gallery-2/18.jpeg', alt: '', category: 'accessories' },
        { src: '../../../img/gallery/gallery-2/19.jpeg', alt: '', category: 'accessories' },
        { src: '../../../img/gallery/gallery-2/20.jpeg', alt: '', category: 'accessories' },
        { src: '../../../img/gallery/gallery-2/21.jpeg', alt: '', category: 'accessories' },
        { src: '../../../img/gallery/gallery-2/22.jpeg', alt: '', category: 'accessories' },
        { src: '../../../img/gallery/gallery-2/23.jpeg', alt: '', category: 'accessories' },
        { src: '../../../img/gallery/gallery-2/24.jpeg', alt: '', category: 'accessories' },
        { src: '../../../img/gallery/gallery-2/25.jpeg', alt: '', category: 'watch' },
        { src: '../../../img/gallery/gallery-2/26.jpeg', alt: '', category: 'watch' },
        { src: '../../../img/gallery/gallery-2/27.jpeg', alt: '', category: 'watch' },
        { src: '../../../img/gallery/gallery-2/28.jpeg', alt: '', category: 'watch' },
        { src: '../../../img/gallery/gallery-2/29.jpeg', alt: '', category: 'watch' },
        { src: '../../../img/gallery/gallery-2/30.jpeg', alt: '', category: 'watch' },
        { src: '../../../img/gallery/gallery-2/31.jpeg', alt: '', category: 'watch' },
        { src: '../../../img/gallery/gallery-3/32.jpeg', alt: '', category: 'watch' },
        { src: '../../../img/gallery/gallery-3/33.jpeg', alt: '', category: 'watch' },
        { src: '../../../img/gallery/gallery-3/34.jpeg', alt: '', category: 'watch' },
        { src: '../../../img/gallery/gallery-3/35.jpeg', alt: '', category: 'watch' },
        { src: '../../../img/gallery/gallery-4/37.jpeg', alt: '', category: 'watch' },
        { src: '../../../img/gallery/gallery-4/38.jpeg', alt: '', category: 'watch' },
        { src: '../../../img/gallery/gallery-4/39.jpeg', alt: '', category: 'watch' },
        { src: '../../../img/gallery/gallery-4/40.jpeg', alt: '', category: 'watch' },
        { src: '../../../img/gallery/gallery-4/41.jpeg', alt: '', category: 'watch' },
        { src: '../../../img/gallery/gallery-4/42.jpeg', alt: '', category: 'watch' },
        { src: '../../../img/gallery/gallery-4/43.jpeg', alt: '', category: 'watch' },
        { src: '../../../img/gallery/gallery-4/44.jpeg', alt: '', category: 'watch' },
        { src: '../../../img/gallery/gallery-4/45.jpeg', alt: '', category: 'watch' },
        { src: '../../../img/gallery/gallery-4/46.jpeg', alt: '', category: 'watch' },
        { src: '../../../img/gallery/gallery-4/47.jpeg', alt: '', category: 'watch' },
        { src: '../../../img/gallery/gallery-4/48.jpeg', alt: '', category: 'watch' },
        { src: '../../../img/gallery/gallery-5/49.jpeg', alt: '', category: 'watch' },
        { src: '../../../img/gallery/gallery-5/50.jpeg', alt: '', category: 'watch' },
        { src: '../../../img/gallery/gallery-5/51.jpeg', alt: '', category: 'watch' },
        { src: '../../../img/gallery/gallery-5/52.jpeg', alt: '', category: 'watch' },
        { src: '../../../img/gallery/gallery-5/53.jpeg', alt: '', category: 'watch' },
        { src: '../../../img/gallery/gallery-6/56.jpeg', alt: '', category: 'watch' },
        { src: '../../../img/gallery/gallery-6/57.jpeg', alt: '', category: 'watch' },
        { src: '../../../img/gallery/gallery-6/58.jpeg', alt: '', category: 'watch' },
        { src: '../../../img/gallery/gallery-6/59.jpeg', alt: '', category: 'watch' },
        { src: '../../../img/gallery/gallery-6/60.jpeg', alt: '', category: 'watch' },
        { src: '../../../img/gallery/gallery-6/61.jpeg', alt: '', category: 'watch' },
        { src: '../../../img/gallery/gallery-6/62.jpeg', alt: '', category: 'watch' },
        { src: '../../../img/gallery/gallery-6/63.jpeg', alt: '', category: 'watch' },
        { src: '../../../img/gallery/gallery-6/64.jpeg', alt: '', category: 'watch' },
        { src: '../../../img/gallery/gallery-6/65.jpeg', alt: '', category: 'watch' },
        { src: '../../../img/gallery/gallery-6/66.jpeg', alt: '', category: 'watch' },
        { src: '../../../img/gallery/gallery-6/67.jpeg', alt: '', category: 'ipad' },
        { src: '../../../img/gallery/gallery-6/68.jpeg', alt: '', category: 'ipad' },
        { src: '../../../img/gallery/gallery-6/69.jpeg', alt: '', category: 'ipad' },
        { src: '../../../img/gallery/gallery-6/70.jpeg', alt: '', category: 'ipad' },
        { src: '../../../img/gallery/gallery-7/80.jpeg', alt: '', category: 'ipad' },
        { src: '../../../img/gallery/gallery-7/81.jpeg', alt: '', category: 'ipad' },
        { src: '../../../img/gallery/gallery-7/82.jpeg', alt: '', category: 'ipad' },
        { src: '../../../img/gallery/gallery-7/83.jpeg', alt: '', category: 'ipad' },
        { src: '../../../img/gallery/gallery-7/84.jpeg', alt: '', category: 'ipad' },
        { src: '../../../img/gallery/gallery-7/85.jpeg', alt: '', category: 'ipad' },
        { src: '../../../img/gallery/gallery-7/86.jpeg', alt: '', category: 'ipad' },
        { src: '../../../img/gallery/gallery-7/87.jpeg', alt: '', category: 'ipad' },
        { src: '../../../img/gallery/gallery-7/88.jpeg', alt: '', category: 'ipad' },
        { src: '../../../img/gallery/gallery-8/89.jpeg', alt: '', category: 'ipad' },
        { src: '../../../img/gallery/gallery-8/90.jpeg', alt: '', category: 'ipad' },
        { src: '../../../img/gallery/gallery-8/91.jpeg', alt: '', category: 'ipad' },
        { src: '../../../img/gallery/gallery-8/92.jpeg', alt: '', category: 'ipad' },
        { src: '../../../img/gallery/gallery-9/93.jpeg', alt: '', category: 'ipad' },
        { src: '../../../img/gallery/gallery-9/94.jpeg', alt: '', category: 'ipad' },
        { src: '../../../img/gallery/gallery-9/95.jpeg', alt: '', category: 'ipad' },
        { src: '../../../img/gallery/gallery-9/96.jpeg', alt: '', category: 'ipad' },
        { src: '../../../img/gallery/gallery-9/97.jpeg', alt: '', category: 'ipad' },
        { src: '../../../img/gallery/gallery-10/98.jpeg', alt: '', category: 'ipad' },
        { src: '../../../img/gallery/gallery-10/99.jpeg', alt: '', category: 'ipad' },
        { src: '../../../img/gallery/gallery-10/100.jpeg', alt: '', category: 'ipad' },
        { src: '../../../img/gallery/gallery-10/101.jpeg', alt: '', category: 'ipad' },
        { src: '../../../img/gallery/gallery-10/102.jpeg', alt: '', category: 'ipad' },
        { src: '../../../img/gallery/gallery-10/103.jpeg', alt: '', category: 'iphone' },
        { src: '../../../img/gallery/gallery-10/104.jpeg', alt: '', category: 'iphone' },
        { src: '../../../img/gallery/gallery-10/105.jpeg', alt: '', category: 'iphone' },
        { src: '../../../img/gallery/gallery-10/106.jpeg', alt: '', category: 'iphone' },
        { src: '../../../img/gallery/gallery-10/107.jpeg', alt: '', category: 'iphone' },
        { src: '../../../img/gallery/gallery-11/108.jpeg', alt: '', category: 'iphone' },
        { src: '../../../img/gallery/gallery-11/109.jpeg', alt: '', category: 'iphone' },
        { src: '../../../img/gallery/gallery-11/110.jpeg', alt: '', category: 'iphone' },
        { src: '../../../img/gallery/gallery-11/112.jpeg', alt: '', category: 'iphone' },
        { src: '../../../img/gallery/gallery-11/113.jpeg', alt: '', category: 'iphone' },
        { src: '../../../img/gallery/gallery-11/114.jpeg', alt: '', category: 'iphone' },
        { src: '../../../img/gallery/gallery-11/115.jpeg', alt: '', category: 'iphone' },
        { src: '../../../img/gallery/gallery-11/116.jpeg', alt: '', category: 'iphone' },
        { src: '../../../img/gallery/gallery-11/117.jpeg', alt: '', category: 'iphone' },
        { src: '../../../img/gallery/gallery-11/118.jpeg', alt: '', category: 'mac' },
        { src: '../../../img/gallery/gallery-11/119.jpeg', alt: '', category: 'mac' },
        { src: '../../../img/gallery/gallery-11/120.jpeg', alt: '', category: 'mac' },
        { src: '../../../img/gallery/gallery-11/121.jpeg', alt: '', category: 'mac' },
        { src: '../../../img/gallery/gallery-11/122.jpeg', alt: '', category: 'mac' },
        { src: '../../../img/gallery/gallery-11/123.jpeg', alt: '', category: 'mac' },
        { src: '../../../img/gallery/gallery-11/124.jpeg', alt: '', category: 'mac' },
        { src: '../../../img/gallery/gallery-11/125.jpeg', alt: '', category: 'mac' },
      
        


 


        // Add all images with appropriate categories
      ];
  
      const buttons: Button[] = [
        { label: 'All categories', category: 'all' },
        { label: 'Mac', category: 'mac' },
        { label: 'Iphone', category: 'iphone' },
        { label: 'Ipad', category: 'ipad' },
        { label: 'Airpods', category: 'airpods' },
        { label: 'Watch', category: 'watch' },
        { label: 'Accessories', category: 'accessories' },



        // Add other categories as needed
      ];
  
      this.state = {
        images,
        filteredImages: images, // Initially display all images
        buttons,
      };
    }
  
    filterImages = (category: string) => {
        this.setState((prevState) => ({
          filteredImages: prevState.images.filter((image) => category === 'all' || image.category === category),
        }));
      };

  public render() {
    return (
      <div>
                <Banner isHome={false} pageName="Gallery" color="white"/>

        <div className="flex items-center justify-center py-4 md:py-8 flex-wrap">
          {this.state.buttons.map((button, index) => (
            <button
              key={index}
              type="button"
              className="text-blue-700 hover:text-white border border-blue-600 bg-white hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-full text-base font-medium px-5 py-2.5 text-center me-3 mb-3 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:bg-gray-900 dark:focus:ring-blue-800"
              onClick={() => this.filterImages(button.category)}
            >
              {button.label}
            </button>
          ))}
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
          {this.state.filteredImages.map((image, index) => (
            <div key={ index }>
              <img className="h-auto max-w-full rounded-lg" src={image.src} alt={image.alt} />
            </div>
          ))}
        </div>
      </div>
    );
  }
}
