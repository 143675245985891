import * as React from 'react';

export interface ISupportProps {
}

export interface ISupportState {
}

export default class Support extends React.Component<ISupportProps, ISupportState> {
  constructor(props: ISupportProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
      <div className='bg-black mt-20'>
        <div className='justify-center items-center pt-3 bg-black'> 
        <h1 className='lg:text-7xl font-bold text-center text-white my-10'>Support</h1>

        </div>
      {/*<!-- Component: Two columns even layout --> */}
      <section className='container my-20'>
        <div className="container px-6 m-auto">
          <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
            <div className="col-span-4 lg:col-span-6">
              <p className="lg:text-2xl font-bold text-white uppercase lg:px-28 text-center lg:mt-20 leading-loose">  Sie haben Ein neues gerät und brauchen hilfe bei der datenübertragung?</p>

            </div>
            <div className="col-span-4 lg:col-span-6">
               <div>
                <img src="../../../img/Support/Support-1.png" alt="" />
               </div>

            </div>
          </div>
        </div>
      </section>
      {/*<!-- End Two columns even layout --> */}
              {/*<!-- Component: One column even layout --> */}
      <section>
        <div className="container px-6 m-auto">
          <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
            <div className="col-span-4 md:col-span-8 lg:col-span-12">
             <h1 className="lg:text-4xl my-6 font-bold text-white uppercase text-center"> Wir helfen </h1>
            </div>
          </div>
        </div>
      </section>
      {/*<!-- End One column even layout --> */}
            {/*<!-- Component: One column even layout --> */}
            <section className='bg-black'>
        <div className="container px-6 m-auto bg-black">
          <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
            <div className="col-span-4 md:col-span-8 lg:col-span-12">
              <img src="../../../img/Support/Support-2.png" alt="" className='lg:container' />
            </div>
          </div>
        </div>
      </section>
      {/*<!-- End One column even layout --> */}
            {/*<!-- Component: One column even layout --> */}
            <section className='bg-black py-3'>
        <div className="container px-16 m-auto bg-black">
          <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
            <div className="col-span-12 md:col-span-8 lg:col-span-12">
              <img src="../../../img/Support/Support-3.png" alt="" className='lg:container ' />
            </div>
          </div>
        </div>
      </section>
      {/*<!-- End One column even layout --> */}
            {/*<!-- Component: One column even layout --> */}
            <section className='bg-black pb-10'>
        <div className="lg:px-16 p-2 m-auto bg-black ">
          <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
            <div className="col-span-4 md:col-span-8 lg:col-span-12 m-auto">
            <p className="lg:text-4xl font-bold text-white uppercase lg:px-28 text-center lg:mt-20 leading-loose text-sm
             border border-white lg:py-6 lg:w-fit rounded-xl ">  
            AUCH BEI EINEM SYSTEMUPGRADE SIND WIR IHNEN BEHILFLICH</p>
            </div>
          </div>
        </div>
      </section>
      {/*<!-- End One column even layout --> */}
      </div>
    );
  }
}
